import React from 'react';
import { Link } from 'gatsby';
import { Container } from './footer.css';

const Footer = () => (
  <Container>
    <div className="container">
      <span className="copyright">
        © {new Date().getFullYear()}{' '}
        <a href="https://www.dawnflyers.de">Dawnflyers Hamburg</a>
      </span>

      <nav>
        <ul className="footer-menu">
          <li>
            <Link to="/imprint">Imprint</Link>
          </li>
        </ul>
      </nav>
    </div>
  </Container>
);

export default Footer;
