import styled from 'styled-components';

export const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4rem;

  h1 {
    font-size: 4rem;
    margin-left: 2rem;
    margin-bottom: 0;
    top: 1rem;
    line-height: 7rem;
    font-weight: 400;
    color: rgb(48, 60, 66);
    font-family: 'Comfortaa', sans-serif;
  }

  .icon {
    display: inline-flex;
    align-self: center;
  }

  .icon svg,
  .icon img {
    height: 65px;
    width: auto;
  }

  .icon.baseline svg,
  .icon img {
    top: 0.125em;
    position: relative;
  }

  a {
    color: #757575;
    transition: color 0.2s ease;
    text-decoration: none;

    &:hover {
      color: inherit;
    }
  }

  @media screen and (max-width: 320px) {
    .icon svg,
    .icon img {
      height: 35px;
      width: auto;
    }
    h1 {
      font-size: 2rem;
      line-height: 4rem;
    }
  }

  @media screen and (min-width: 321px) and (max-width: 702px) {
    .icon svg,
    .icon img {
      height: 45px;
      width: auto;
    }
    h1 {
      font-size: 2.5rem;
      line-height: 5rem;
    }
  }
`;
