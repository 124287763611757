import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Head from 'components/head';
import Header from 'components/header';
import GlobalStyle from 'global.css.js';
import Footer from '../footer/footer';
import { ThemeProvider } from 'styled-components';
import { withTheme } from 'styled-components';

const theme = {
  breakpoints: ['40em', '52em', '64em', '90em', '120em'],
};

const Layout = ({ data, children }) => (
  <ThemeProvider theme={theme}>
    <div>
      <GlobalStyle />
      <Head />
      <Header title={data.site.siteMetadata.siteTitle} />
      {children}
      <Footer />
    </div>
  </ThemeProvider>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.object.isRequired,
};

const LayoutWithQuery = props => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            siteTitle
          }
        }
      }
    `}
    render={data => (
      <ThemeProvider theme={theme}>
        <Layout data={data} {...props} />
      </ThemeProvider>
    )}
  />
);

LayoutWithQuery.propTypes = {
  children: PropTypes.node.isRequired,
};

LayoutWithQuery.defaultProps = {
  theme: theme,
};

export default withTheme(LayoutWithQuery);
