import styled from 'styled-components';

export const Container = styled.footer`
  padding: 4rem;
  text-align: center;

  span,
  nav {
    display: inline-block;
  }

  nav {
    margin-left: 1rem;

    li {
      &:before {
        padding-right: 1rem;
        content: '|';
        display: inline;
      }
    }
  }

  a {
    color: #757575;
    transition: color 0.2s ease;
    text-decoration: none;

    &:hover {
      color: inherit;
    }
  }
`;
